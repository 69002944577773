<template>
<div class="wp-body" v-loading.fullscreen.lock="loading" :element-loading-text="$t('connecting')" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <header class="wp-header">
            <div class="grid grid-cols-12 gap-3 flex-1">
                <div class="col-span-3">
                    <el-input class="w-full" :placeholder="$t('search')" prefix-icon="el-icon-search" v-model="search" @keyup.enter.native="__GET()">
                    </el-input>
                </div>
            </div> 
            <button v-if="role !== 'admin'" class="button dark-yellow" @click="$router.push('/reservoir/canals/create')" >
                <i class="el-icon-plus"></i>
                {{$t('add_new_canals')}}
            </button>
        </header>
        <el-table :data="resList.results" style="width: 99%;margin-bottom: 20px;" row-key="id">
            <el-table-column label="№" width="70" align="center">
                <template slot-scope="scope">
                    {{ (current_page-1)*item_count + (scope.$index + 1) }}
                </template>
            </el-table-column>
            <el-table-column :label="$t('canal_name')" prop="name_cyr"  align="center">
                <template slot-scope="scope">
                    <router-link  :to="`/reservoir/canals/${scope.row.id}/1`" class="router-link">{{ scope.row[`name_${$i18n.locale}`] ? scope.row[`name_${$i18n.locale}`] : scope.row.name_cyr  }}  <i class="el-icon-top-right"></i> </router-link>
                </template>
            </el-table-column>
            <el-table-column :label="$t('canal_adress')"   prop="address" align="center">
            </el-table-column>
            <el-table-column :label="$t('canal_year_of_construction')"   prop="year_of_construction"  align="center">
            </el-table-column>
            <el-table-column :label="$t('canals_date_of_accept')"   prop="date_of_accept" align="center">
            </el-table-column>
            <el-table-column :label="$t('canals_reservoir_class')"   prop="reservoir_class.name_cyr"  align="center">
            </el-table-column>
            <el-table-column :label="$t('finalconclusion')"   prop="finalconclusion.name_cyr"  align="center">
            </el-table-column>
            <el-table-column :label="$t('canal_passport')" width="100" align="center" v-if="role == 'admin'">
            <template slot-scope="scope">
                <button v-if="scope.row.file" @click="GeneratePasport(scope.row.file)" class="el-icon-s-order el-button-download"> </button>
            </template>
            </el-table-column>
            <el-table-column :label="$t('see')" width="100" align="center">
                <template slot-scope="scope">
                    <div class="d-flex justify-center">
                       <el-button size="small" @click="$router.push({path:`/reservoir/canals/${scope.row.id}/1`})"  class="el-icon-view el-button-download"></el-button>
                    </div>
                </template>
            </el-table-column>
            <el-table-column :label="$t('change')" width="120" align="center" v-if="role!=='admin'">
                <template slot-scope="scope">
                    <div class="d-flex justify-center">
                       <el-button size="small" @click="$router.push({path:`/reservoir/canals/${scope.row.id}/0`})"  class="el-icon-edit el-button-download"></el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <!-- <pre>{{ userList. }}</pre> -->

        <el-pagination style="margin-left: auto; margin-top: auto" class="ml-auto" :page-sizes="[10, 20, 50, 100]" :page-size="5" @current-change="paginate" @size-change="paginateSize" :current-page.sync="current_page" :hide-on-single-page="false" background layout="sizes, prev, pager, next" :page-count="page_count">
        </el-pagination>

        <el-drawer :title="$t('canal_passport')" :visible.sync="drawer" direction='rtl'>
        <div>
            <div class="pdf">
                <vue-pdf ref="print" :src="pdf"></vue-pdf>
                <div class="loading-pdf" v-show="loadDawer">
                    <i class="el-icon-loading"></i>
                </div>
            </div>
            <div class="w-full pdf-control">
                <button class="print" @click="downloadFile(pdf)"><i class="el-icon-download mr-2"></i> {{$t('download')}}</button>
                <a :href="pdf" target="_blank">
                    <button class="ml-3 print"><i class="el-icon-view mr-2"></i> {{$t('see')}}</button>
                </a>
            </div>
        </div>
    </el-drawer>
</div>
</template>

<script>
export default {
    data() {
        return {
            pdf: '',
            namePdf: '',
            loadDawer: false,
            drawer: false,
            resList: [],
            search: '',
            page_count: 1,
            loading: false,
            userList: [],
            input2: null,
            items: [{
                message: "Foo"
            }, {
                message: "Bar"
            }],
            currentTab: 1,
            current_page: 1,
            item_count: 10,
        };
    },
    computed: {
        role() {
            return this.$store.state.role;
        },
    },
    watch:{
        role(){
            this.current_page = this.$route.query.page || 1;
            this.__GET()
        }
    },
    mounted() {
        this.current_page = this.$route.query.page || 1;
        this.__GET()
    },
    methods: {
        GeneratePasport(a) {
            this.drawer = true
            this.pdf=a
            // this.loadDawer = true
            // axios.post(`/reservoir/canals/${id}/generate/`)
            //     .then(res => {
            //         console.log(res)
            //         this.pdf = axios.defaults.baseURL.slice(0, -8) + res.data.pdf_link

            //     })
            //     .catch(e => {

            //     })
            //     .finally(() => {
            //         this.loadDawer = false
            //     })
        },
        downloadFile(file) {
            fetch(file)
                .then((resp) => resp.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    // the filename you want
                    a.download = 'Pasport' || "file.json";
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
        },
        paginate(val) {
            this.current_page = val;
            this.__GET()
        },
        paginateSize(val) {
            this.item_count = val
            this.__GET();
        },
        __GET() {
            this.loading = true;
            let offset = this.item_count * (this.current_page - 1);
            if(this.role=='admin'){
                axios.get(`/reservoir/canals/?list=selection&limit=${this.item_count}&offset=${offset}&search=${this.search}`)
                .then((response) => {
                    this.resList = response.data;
                    this.page_count = Math.ceil(response.data.count / this.item_count);
                })
                .finally(() => {
                    this.loading = false;
                })
            }
            else{
                 axios.get(`/reservoir/canals/?limit=${this.item_count}&offset=${offset}&search=${this.search}`)
                .then((response) => {
                    this.resList = response.data;
                    this.page_count = Math.ceil(response.data.count / this.item_count);
                })
                .finally(() => {
                    this.loading = false;
                })
            }

        }
    }
};
</script>

<style lang="scss" scoped>
tbody tr td:last-child {
    display: flex;
    justify-content: center;
}

ul {
    display: flex;
    margin-bottom: 20px;

    // flex-wrap: wrap;
    li {
        cursor: pointer;
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: solid 1px #d4d4d4;
        font-size: 17px;
        line-height: 24px;
        color: #004787;
        // position: relative;
        user-select: none;

        &.active {
            border-bottom: solid 3px #004787;
        }
    }
}

</style>
