<template>
    <div class="obj-list wrapper">
        <div class="header-container mb-8">
            <router-link tag="button" to="/reservoir" class="header-item" active-class="header-item-active" exact>
                <div class="header-radio-label"></div> {{ $t('woter_base') }}
            </router-link>
            <router-link tag="button" to="/reservoir/hydrounits" class="header-item" active-class="header-item-active">
                <div class="header-radio-label"></div>{{ $t('hydrounits') }}
            </router-link>
            <router-link tag="button" to="/reservoir/pumpingstations" class="header-item"
                active-class="header-item-active">
                <div class="header-radio-label"></div>{{ $t('pumpingstations') }}
            </router-link>
            <router-link tag="button" to="/reservoir/hydroelectricpowerstations" class="header-item"
                active-class="header-item-active">
                <div class="header-radio-label"></div>{{ $t('hydroelectricpowerstations') }}
            </router-link>
            <router-link tag="button" to="/reservoir/canals" class="header-item" active-class="header-item-active">
                <div class="header-radio-label"></div>{{ $t('canals') }}
            </router-link>
        </div>
        <div class="w-full " style="padding-bottom:20px;  ">
            <Canals v-if="$route.params.type == 'canals'" />
            <WaterBase v-if="$route.params.type == undefined" />
            <Hydrounits v-if="$route.params.type == 'hydrounits'" />
            <Hydroelectricpowerstations v-if="$route.params.type == 'hydroelectricpowerstations'" />
            <Pumpingstations v-if="$route.params.type == 'pumpingstations'" />
        </div>
    </div>
</template>

<script>
import Canals from '@/components/Reservoir/Canals'
import WaterBase from '@/components/Reservoir/Water'
import Hydrounits from '@/components/Reservoir/Hydrounits'
import Hydroelectricpowerstations from '@/components/Reservoir/Hydroelectricpowerstations'
import Pumpingstations from '@/components/Reservoir/Pumpingstations'
export default {
    components: {
        Canals,
        WaterBase,
        Hydrounits,
        Hydroelectricpowerstations,
        Pumpingstations
    },
    data() {
        return {
            type: 'woterbase'
        };
    },
    computed: {
        me() {
            return this.$store.state.me;
        },
        role() {
            return this.$store.state.role;
        },
    },
    mounted() {
        // if (this.$route.params.type == undefined) {
        //     this.$router.push('/reservoir/woterbase')
        // }
    }
}
</script>
